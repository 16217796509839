// App.js
import React, { useState, useEffect } from "react";
import "./Carousel.css";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel({data}) {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [slidesData, setSlidesData] = useState([]);
    const [slidesToShow, setSlidesToShow] = useState(3);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
        if(data.images) {
            setSlidesData(data.images);
            setLoading(false)
            if(data.images.length < 3) {
                setSlidesToShow(data.images.length)
            }
        }
    }, [data,slider1,slider2]);

    const settingsMain = {
        adaptiveHeight: true,
        lazyLoad: 'progressive',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: ".slider-nav",
    };

    const settingsThumbs = {
        lazyLoad: 'progressive',
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        asNavFor: ".slider-for",
        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: "10px",
    };

    return (
        <div>
            {loading && (<h2> Chargement </h2>)}
            {!loading && (
                <div className="slider-wrapper">
                    <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}>
                        {slidesData.map((slide) => (
                            <div className="slick-slide" key={slide.id}>
                                <img
                                    alt="img"
                                    className="slick-slide-image"
                                    src={process.env.PUBLIC_URL + data.path + slide}
                                />
                            </div>
                        ))}
                    </Slider>
                    {slidesToShow > 1 && (
                        <div className="thumbnail-slider-wrap">
                            <Slider
                            {...settingsThumbs}
                            asNavFor={nav1}
                            ref={(slider) => setSlider2(slider)}>
                                {slidesData.map((slide) => (
                                    <div className="slick-slide" key={slide.id}>
                                        <img
                                            alt="img"
                                            className="slick-slide-image"
                                            src={process.env.PUBLIC_URL + data.path + slide}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Carousel;
