import { useState, useEffect } from "react";
import {Navbar, Nav} from "react-bootstrap";
import {Link, NavLink} from 'react-router-dom'
import {getPath} from '../Tools/Regex'
import './TopBar.scss'

function TopBar() {
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [path, setPath] = useState(null);
    useEffect(() => {
        // let url = process.env.NODE_ENV == "production" ? process.env.REACT_APP_API : "";
        let url = "/api/menu"; 
        fetch(url)
        .then(res => res.json())
        .then(data => {
            data.success !== false ? setMenus(data) : setError(true);
            setLoading(false);
        })
        .catch(e => {
            setError(true);
            setLoading(false);
        });
        let pathToExtract = getPath.exec(window.location.pathname);
        setPath(pathToExtract[0]);
    }, []);

    return (
        <Navbar bg="primary" variant="dark" expanded={expanded} expand="lg" className="navbar" style={{fontSize:"120%", position:"fixed",top:0,width:"100%", zIndex:1000}}>
            <Navbar.Brand className="navBar-brand" href="/">DS Exotiques</Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav activeKey={path} className="mr-auto">
                    {!loading && !error && (menus.map((menu) => (
                        <NavLink key={menu.id} onClick={() => setExpanded(false)} className="nav-link" to={menu.path}>{menu.nom}</NavLink>
                    )))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default TopBar;
