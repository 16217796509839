import "./App.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function App() {
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        try {
            let title = document.title.split('-')[0];
            document.title = `${title} - Accueil`;
            
            // let url = process.env.NODE_ENV == "production" ? process.env.REACT_APP_API : "";
            let url = "/api/menu"; 
            fetch(url)
            .then(res => res.json())
            .then(data => {
                data.success !== false ? setMenus(data) : setError(true);
            })
            .finally(
                setLoading(false)
            )
            // .catch(e => {
            //     setError(true)
            //     setLoading(false)
            // });
        }
        catch (e) {
            setError(true)
        }
        finally {
            setLoading(false)
        }
    }, []);

    return (
        <div id="divPrincipale">
            <br/>
            <div id="mainDiv">
                {loading && (
                    <div className="mainDivItem">
                        <h2 className="title">Chargement</h2>
                    </div>
                )}
                {error && (
                    <div className="mainDivItem">
                        <h2 className="title">Erreur lors du charment des catégories</h2>
                    </div>
                )}
                {!loading && !error && (menus.map((menu) => (
                    <Link
                        key={menu.id}
                        to={menu.path}
                        className="mainDivItem"
                        // style={{ marginLeft: "5%", marginRight: "5%" }}
                    >
                        <h2 className="title">{menu.nom}</h2>
                        <div className="divider"> </div>
                        <img
                            className="imgMain"
                            src={process.env.PUBLIC_URL + menu.img}
                            alt={menu.nom}
                        />
                    </Link>
                )))}
            </div>
        </div>
    );
}

export default App;
