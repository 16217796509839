import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import { Link } from "react-router-dom";
import './Liste.css'
import { MobileView, isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal'
import smoothscroll from 'smoothscroll-polyfill';

function Liste() {
    const [elements, setElements] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [listPage, setListPage] = useState([]);
    const [showPagination, setShowPagination] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    let categorie = window.location.pathname.slice(0,-1)
    
    library.add(faFolderPlus);

    
    useEffect(() => {
        let menu = JSON.parse(localStorage.getItem('menu'));
        let categorieInfo = menu.find(el => `${categorie}/` == el.path);

        let title = document.title.split('-')[0];
        
        // let url = process.env.NODE_ENV == "production" ? process.env.REACT_APP_API : "";
            // On affecte le nom de la catégorie au titre de la page
            document.title = `${title} - ${categorieInfo.nom}`;
        // url = process.env.NODE_ENV == "production" ? process.env.REACT_APP_API : "";

        let url = `/api/read/allModeles?id_categorie=${categorieInfo.id}&isMobile=${isMobile}`
        if(isMobile) {
            url += `&itemsPerPage=${itemsPerPage}&activePage=${activePage}`
        }
        fetch(url)
        .then(res => res.json())
        .then(data => {
            if (!data.success) throw new Error ('erruer');
            console.log('data :', data);
            setElements(data.data)
            setShowPagination(data.total > itemsPerPage);
            setListPage([])
            for (let number = 1; number <= (Math.ceil(data.total / itemsPerPage)); number++) {
                setListPage(oldArray => [...oldArray, 
                    <Pagination.Item key={number} active={number === activePage} onClick={(event) => {
                        if(!isNaN(event.target.text)) {
                            setActivePage(parseInt(event.target.text));
                            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                        }
                    }}>
                        {number}
                    </Pagination.Item>,
                ])
            }
        })
        .catch(e => {
            setError(true)
        })
        .finally(() => {
            setLoading(false)
        });
        
    }, [activePage, categorie, itemsPerPage]);
    

    return (
        <div id="menu">
            {loading && (
                <div className="mainDivItem">
                    <h2 className="title">Chargement</h2>
                </div>
            )}
            {error && (
                <div className="mainDivItem">
                    <h2 key="error" className="title">Erreur lors du charment de la catégorie demandée</h2>
                </div>
            )}
            {!loading && !error && (
                <div id="menuDiv">
                    {elements.map( (element) => (
                        <Link
                            to={location => ({
                                pathname: element.folder == undefined ? location.pathname+element.nom_modele : "",
                                state: element
                            })}
                            key={element.id_modele}
                            className="itemDiv-mainPart"
                            onClick={() => {
                                if(element.folder !== undefined) {
                                    setModalData(element);
                                    setShowModal(true);
                                }
                            }}
                            >
                            <div className="itemDiv-mainPart-div-img">
                                <img className="itemDiv-mainPart-img" src={'/images/'+element.url[0]} alt={element.nom_modele} />
                            </div>
                            <div className="itemDiv-mainPart-name">
                                <h3 className="itemDiv-mainPart-name-title" >
                                    {element.nom_modele} { element.folder !== undefined && (<FontAwesomeIcon style={{color:"GrayText"}} icon={faFolderPlus}/>)}
                                </h3>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
            {showModal && (
                <Modal show={showModal} onHide={()=>setShowModal(false)} centered>
                    <Modal.Header closeButton >
                    <Modal.Title>{modalData.nom}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBody">
                        <div className="modalGrid">
                            {modalData.folder.reverse().map((data) => (
                                <Link
                                    to={location => ({
                                        pathname: location.pathname+modalData.nom,
                                        state: {nom:modalData.nom, description:modalData.description, path:data.path, img:data.img}
                                    })}
                                    key={data.id}
                                    className='modalDiv'>
                                        <img className='modalDivImg' src={process.env.PUBLIC_URL + data.path + data.main_img} alt={data.nom} />
                                </Link>
                            ))}
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                    </Modal.Footer> */}
                </Modal>
            )}
            { showPagination && (
                <MobileView>
                    <div className='alignPagination'>
                        <Pagination>
                            {listPage}
                        </Pagination>
                    </div>
                </MobileView>
            )}
        </div>
    )
}

export default Liste;