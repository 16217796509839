import { useEffect, useState } from 'react'
import './Detail.css'
import Carousel from '../Carousel/Carousel'

function Plat(props) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    let styleName = window.innerWidth >= 600 ? "detail-mainDiv" : "mobile-mainDiv";
    useEffect(() => {
        let nomProduit
        if(props.location.state !== undefined) {
            setData(props.location.state);
            nomProduit = props.location.state.nom;
            setLoading(false);
        }
        else if (!error) {
            // let url = process.env.NODE_ENV == "production" ? process.env.REACT_APP_API : "";
            let url = `/api/produits${window.location.pathname}`
            fetch(`${url}`)
            .then(res => res.json())
            .then(data => {
                if(data.success == false) {
                    return setError(true)
                }
                setData(data.data)
                nomProduit = data.data.nom;
            })
            .catch(e => {
                setError(true);
            });
            setLoading(false);
        }
        let title = document.title.split('-')[0];
        // On affecte le nom de la catégorie au titre de la page
        document.title = `${title} - ${nomProduit}`;
    },[])

    return (
        <div id="detail-menuDiv">
            {loading && (<h2> Chargement </h2>)}
            {error && (<h2> Erreur lors du chargement du produit demandé </h2>)}
            {(!loading && !error && data) && (
                <div className="detail-mainDiv">
                    <Carousel data={{path:data.path, images:data.img}}/>
                    <div className='detail-mainDiv-infos'>
                        <h2 className='detail-mainDiv-infos-nom'> {data.nom} </h2>
                        <div className='detail-mainDiv-infos-description' >
                            {data.description}
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default Plat;