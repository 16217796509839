import React, { useEffect } from "react";
// import ReactDOM from "react-dom";
import "./index.scss";
// import IndexComponent from "./IndexComponent";
import App from "./App";
import TopBar from "./components/TopBar/TopBar";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Liste from "./components/Liste/Liste";
import Detail from "./components/Detail/Detail";
import "bootstrap/dist/css/bootstrap.css";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
const IndexRoot = () => {
    useEffect(() => {
        let url = "/api/menu"; 
        if(localStorage.getItem('menu') == null) {
            fetch(url)
            .then(res => res.json())
            .then(data => {
                let menu = data.map(({img, ...rest}) => rest);
                if(data) localStorage.setItem('menu',JSON.stringify(menu))
            })
        }
    }, []);
    
    return (
        <Router>
            <TopBar />
            <Route exact path="/" component={App} />
            <Route exact path="/:menu/" component={Liste} />
            <Route exact path="/:menu/:name/" component={Detail} />
        </Router>
    )
}

if (rootElement.hasChildNodes()) {
    hydrate(<IndexRoot />, rootElement);
} else {
    render(<IndexRoot />, rootElement);
}

// ReactDOM.render(
// <Router>
//     <TopBar />
//     <Route exact path="/" component={IndexComponent} />
//     <Route exact path="/:menu/" component={Liste}/>
//     <Route exact path="/:menu/:name/" component={Detail}/>
// </Router>,
// document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
